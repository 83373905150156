import Swiper, { Autoplay, EffectFade, Grid, Navigation } from "swiper";

// configure Swiper to use modules
Swiper.use([Autoplay, EffectFade, Grid, Navigation]);

function initHero() {
    const heroSwiperEl = document.querySelector(".hero .swiper.should-swipe");
    if (heroSwiperEl) {
        const heroSwiper = new Swiper(heroSwiperEl, {
            loop: true,
            effect: "fade",
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 6000,
            },
            speed: 500,
        });
    }
}

function initTeamSlider() {
    const teamSwiperEl = document.querySelector(".team-slider");
    if (teamSwiperEl) {
        var teamSwiper = new Swiper(teamSwiperEl, {
            navigation: {
              nextEl: ".team-swiper-button-next",
              prevEl: ".team-swiper-button-prev",
            },
            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    grid: {
                        rows: 2,
                    }
                },
                1024: {
                    slidesPerView: 4,
                    grid: {
                        rows: 3,
                    }
                }
            },
        });
    }
}

function initOfficeSlider() {
    const officeSwiperEl = document.querySelector(".office-slider");
    if (officeSwiperEl) {
        var teamSwiper = new Swiper(officeSwiperEl, {
            navigation: {
              nextEl: ".office-swiper-button-next",
              prevEl: ".office-swiper-button-prev",
            },
        });
    }
}

export default function () {
    initHero();
    initTeamSlider();
    initOfficeSlider();
}
