import Macy from "macy";

export default function () {
    const jobsGrid = document.getElementById("jobs-grid");
    if (jobsGrid) {
        var macy = Macy({
            container: "#jobs-grid",
            trueOrder: false,
            waitForImages: false,
            margin: 70,
            columns: 4,
            breakAt: {
                1115: 3,
                768: 2,
                450: 1,
            },
        });

        var jobLocation = null;
        var jobFunction = null;

        const noResults = document.getElementById("no-results");
        const locationSel = document.getElementById("location");
        locationSel.addEventListener("change", (e) => {
            jobLocation = locationSel.value;
            updateJobs();
        });
        const functionSel = document.getElementById("function");
        functionSel.addEventListener("change", (e) => {
            jobFunction = functionSel.value;
            updateJobs();
        });

        var updateJobs = function () {
            const jobs = Array.from(document.querySelectorAll(".jobs-list-item"));
            var jobsCount = jobs.length;

            jobs.forEach((job) => {
                let isHidden = false;
                job.classList.remove("hidden");
                if(jobLocation) {
                    if (job.dataset.location != jobLocation) {
                        // console.log('location incorrect, hide', job.dataset.location, jobLocation);
                        job.classList.add("hidden");
                        isHidden = true;
                    }
                }
                if(jobFunction) {
                    if (job.dataset.function != jobFunction) {
                        // console.log('function incorrect, hide', job.dataset.function, jobFunction);
                        job.classList.add("hidden");
                        isHidden = true;
                    }
                }
                if(isHidden) {
                    jobsCount--;
                }
            });

            if (jobsCount > 0) {
                jobsGrid.classList.remove("hidden");
                macy.recalculate(true);
                noResults.classList.add("hidden");
            } else {
                jobsGrid.classList.add("hidden");
                noResults.classList.remove("hidden");
            }

            // Scroll to jobs grid , smooth …
            window.scrollToTarget('#jobs-grid');
            // var element = document.getElementById('jobs-grid');
            // var headerOffset = document.querySelector('.header').clientHeight;
            // console.log('headerOffset', headerOffset);
            // var elementPosition = element.getBoundingClientRect().top;
            // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            // window.scrollTo({
            //     top: offsetPosition,
            //     behavior: "smooth"
            // });
        };
        if(!document.getElementById("jobs-map")) return;
    
        var map = new mapboxgl.Map({
            container: "jobs-map",
            style: "mapbox://styles/mapbox/light-v10",
            center: [4.219710, 51.027620],
            zoom: 8.0,
            minZoom: 5,
            maxZoom: 16
        });
        // map.addControl(new mapboxgl.NavigationControl())
    
        map.on("load", function () {
            
            if( jobLocationsJson !== undefined ) {
    
                map.addSource("jobLocations", {
                    type: "geojson",
                    data: jobLocationsJson,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });
                
                map.addLayer({
                    id: "clusters",
                    type: "circle",
                    source: "jobLocations",
                    filter: ["has", "point_count"],
                    paint: {
                        "circle-color": "#232755",
                        "circle-stroke-color": "#232755",
                        "circle-stroke-width": 1,
                        "circle-radius": [
                            "step",
                            ["get", "point_count"],
                            12,
                            5, 18,
                            10, 26
                        ],
                    }
                });
            
                map.addLayer({
                    id: "cluster-count",
                    type: "symbol",
                    source: "jobLocations",
                    filter: ["has", "point_count"],
                    layout: {
                        "text-field": "{point_count_abbreviated}",
                        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                        "text-size": 12                    
                    },
                    paint: {
                        "text-color": "#FFFFFF"
                    }
                });

                map.addLayer({
                    id: "unclustered-point",
                    type: "circle",
                    source: "jobLocations",
                    filter: ["!", ["has", "point_count"]],
                    paint: {
                        "circle-color": "#232755",
                        "circle-stroke-color": "#232755",
                        "circle-stroke-width": 1,
                        "circle-radius": [
                            "step",
                            2,
                            12,
                            5, 18,
                            10, 26
                        ],
                    }
                });
                
                map.addLayer({
                    id: "unclustered-count",
                    type: "symbol",
                    source: "jobLocations",
                    filter: ["!", ["has", "point_count"]],
                    layout: {
                        "text-field": "1",
                        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                        "text-size": 12                    
                    },
                    paint: {
                        "text-color": "#FFFFFF"
                    }
                });

                map.on('click', 'clusters', function (e) {
                    var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
                    var clusterId = features[0].properties.cluster_id;
                    var point_count = features[0].properties.point_count;
                    map.getSource('jobLocations').getClusterLeaves(clusterId, point_count, 0, function(err, aFeatures){
                        aFeatures
                        locationSel.value = aFeatures[0].properties.slug;
                        jobLocation = aFeatures[0].properties.slug;
                        updateJobs();
                    });
                });
    
                map.on('click', 'unclustered-point', function (e) {
                    locationSel.value = e.features[0].properties.slug;
                    jobLocation = e.features[0].properties.slug;
                    updateJobs();
                });
            
                map.on('mouseenter', 'clusters', function () {
                    map.getCanvas().style.cursor = 'pointer';
                });
        
                map.on('mouseleave', 'clusters', function () {
                    map.getCanvas().style.cursor = '';
                });
    
                // Fit bounds to geojson
                const bounds = new mapboxgl.LngLatBounds(
                    jobLocationsJson.features[0].geometry.coordinates,
                    jobLocationsJson.features[0].geometry.coordinates
                );
                for (const ft of jobLocationsJson.features) {
                    bounds.extend(ft.geometry.coordinates);
                }
    
                // map.fitBounds(bounds, {
                //     padding: 40
                // });
            }
            
        });
    
        map.addControl(new mapboxgl.NavigationControl());
        map.scrollZoom.disable();

    }

}
