import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

const duration = 300;

window.scrollToTarget = function (selector) {
    const target = document.querySelector(selector);
    if(target) {
        const top = target.getBoundingClientRect().top;
        const startPos = window.pageYOffset;
        let startTime = null;
        let requestId;
        
        const loop = function (currentTime) {
            // Check header height for offset
            var headerOffset = document.querySelector('.header').clientHeight;
            const diff = top - headerOffset;

            if (!startTime) {
                startTime = currentTime;
            }
    
            // Elapsed time in miliseconds
            const time = currentTime - startTime;
    
            const percent = Math.min(time / duration, 1);
            window.scrollTo(0, startPos + diff * percent);
    
            if (time < duration) {
                // Continue moving
                requestId = window.requestAnimationFrame(loop);
            } else {
                window.cancelAnimationFrame(requestId);
            }
        };
        requestId = window.requestAnimationFrame(loop);
    }
};

window.scrollToAnchor = function (selector) {
    const el = document.querySelector(selector);

    if (el) {
        const marginOffset = parseInt(window.getComputedStyle(el).marginTop, 10); // Get margin top
        const headerOffset = document.querySelector(".header").clientHeight; // Get header height
        const blockOffset = el.getBoundingClientRect().top + window.pageYOffset; // Get top position of our block

        const y = blockOffset - headerOffset - marginOffset;

        window.scrollTo({
            top: y,
            behavior: "smooth",
        });

        // Close navigation
        document.body.classList.remove("nav-open");
    }
}

export default function () {
    const anchors = Array.from(document.querySelectorAll('a[href^="#"]'));
    // const mainNavLinks = document.querySelectorAll(".main-nav ul li a");

    anchors.forEach((anchor) => {
        anchor.addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollToAnchor(e.target.hash);
        });
    });

    // window.addEventListener("scroll", (event) => {
    //     let fromTop = window.scrollY;

    //     mainNavLinks.forEach((link) => {
    //         let section = document.querySelector(link.hash);

    //         if (section) {
    //             const marginOffset = parseInt(window.getComputedStyle(section).marginTop, 10); // Get margin top
    //             const headerOffset = document.querySelector(".header").clientHeight; // Get header height
    //             const blockOffset = section.getBoundingClientRect().top + window.pageYOffset; // Get top position of our block

    //             const y = blockOffset - headerOffset - marginOffset;

    //             if (y <= fromTop && y + section.offsetHeight > fromTop) {
    //                 link.classList.add("active");
    //             } else {
    //                 link.classList.remove("active");
    //             }
    //         }
    //     });
    // });
}
