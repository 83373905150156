import Macy from "macy";

export default function () {
    
    if( document.getElementById('locations-grid') ) {
        var macy = Macy({
            container: '#locations-grid',
            trueOrder: false,
            waitForImages: false,
            margin: 70,
            columns: 4,
            breakAt: {
                1115: 3,
                768: 2,
                450: 1
            }
        });
    }

    if(!document.getElementById("locations-map")) return;

    var map = new mapboxgl.Map({
        container: "locations-map",
        style: "mapbox://styles/mapbox/light-v10",
        center: [4.219710, 51.027620],
        zoom: 8.0,
        minZoom: 5,
        maxZoom: 16
    });
    // map.addControl(new mapboxgl.NavigationControl())

    map.on("load", function () {
        
        if( locationsJson !== undefined ) {

            map.addSource("locations", {
                type: "geojson",
                data: locationsJson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50
            });
            
            map.addLayer({
                id: "clusters",
                type: "circle",
                source: "locations",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": "#232755",
                    "circle-stroke-color": "#232755",
                    "circle-stroke-width": 1,
                    "circle-radius": [
                        "step",
                        ["get", "point_count"],
                        12,
                        5, 18,
                        10, 26
                    ],
                }
            });
        
            map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "locations",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12                    
                },
                paint: {
                    "text-color": "#FFFFFF"
                }
            });
            
            map.loadImage('./img/static/icon-location.png', (error, image) => {
                if (error) throw error;
                map.addImage('location-icon', image, { 'sdf': true });
                map.addLayer({
                    id: "unclustered-point",
                    source: "locations",
                    type: "symbol",
                    filter: ["!", ["has", "point_count"]],
                    layout: {
                        'icon-image': 'location-icon',
                        // 'icon-size': 0.5
                        "icon-size": [
                            'interpolate', 
                            ['linear'], 
                            ['zoom'], 
                            5, 0.2, 
                            10, 0.3, 
                            15, 0.5
                        ]
                    },
                    paint: {
                        'icon-color': "#232755"
                    }
                });
            });
            
            map.on('click', 'clusters', function (e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
                var clusterId = features[0].properties.cluster_id;
                map.getSource('locations').getClusterExpansionZoom(clusterId, function (err, zoom) {
                    if (err)
                        return;
                    
                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                });
            });

            map.on('click', 'unclustered-point', function (e) {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                new mapboxgl.Popup({
                    // "anchor": "top-left",
                    "maxWidth": "300px",
                    "className": "location-infowindow",
                    "closeButton": true,
                    "offset": 10
                })
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(map);
            });
        
            map.on('mouseenter', 'clusters', function () {
                map.getCanvas().style.cursor = 'pointer';
            });
    
            map.on('mouseleave', 'clusters', function () {
                map.getCanvas().style.cursor = '';
            });

            // Fit bounds to geojson
            const bounds = new mapboxgl.LngLatBounds(
                locationsJson.features[0].geometry.coordinates,
                locationsJson.features[0].geometry.coordinates
            );
            for (const ft of locationsJson.features) {
                bounds.extend(ft.geometry.coordinates);
            }

            // map.fitBounds(bounds, {
            //     padding: 40
            // });
        }
        
    });

    map.addControl(new mapboxgl.NavigationControl());
    map.scrollZoom.disable();
    

}