import "core-js/stable";
import "regenerator-runtime/runtime";
import "../sass/site.scss";
import header from "./header";
import scrollSmooth from "./scroll-smooth";
import swiper from "./swiper";
import jobs from "./jobs";
import locations from "./locations";
import AOS from 'aos';

scrollSmooth();
swiper();
header();
jobs();
locations();
AOS.init();